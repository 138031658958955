// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = true;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;\


// Move FooterLogos out of footer to go FW
$( '.Footerlogos' ).insertAfter( '.pageFooter' );


// Move Where after When on Event pages
$( '.eventsPostBody .eventWhereWrapper' ).insertAfter( '.eventsPostBody .eventWhenWrapper' );


// Meet the team page - Making posts hover only - no click
$(".listedPost.PostCategory_unclickable").prop("onclick", null).css("pointer-events", "none"); //.off("click");


// Function to display start and end dates on homepage events
if ($('body').hasClass('homepage')) {
  $('.feedItem[onclick*="events"]').each(function(){
    var startDate = $(this).find('.associatedStartDate time span:first-child').text();
    var endDate = $(this).find('.associatedEndDate time span:first-child').text();
    $(this).find('.associatedStartDate time span:first-child').text(startDate + ' - ' + endDate);
  })
}


// Appeal - Sidebar on/off
$(document).ready(function () {
  
  if ( $(".appealPostPage .appealActions .fundraiseBtn").length == 0) {
    $(".findAFundraiserWidget").addClass("hidden");
  }

  if ( $(".appealPostPage .findAFundraiserWidget").hasClass("hidden") && $(".appealPostPage .appealTotaliser").hasClass("hidden") ) {
    $(".appealPostPage .contentBlock ").css("display", "block");
  };
});